<template>
  <div class="holder-normal h-font-lg">
    <div class="base h-group">
        <div class="h-cell__content">
          <HYForm
            ref="viewHolderFormRef"
            fmCode="viewHolder"
            :config="viewHolderConfig"
            :form="holderInfo">
          </HYForm>
          <!-- <van-row>
              <van-col span="6"  class="h-text-color_gray-6">姓名</van-col>
              <van-col span="18">{{ holderInfo.name }}</van-col>
          </van-row>
          <van-row>
              <van-col span="6" class="h-text-color_gray-6">证件号码</van-col>
              <van-col span="18">{{ holderInfo.certificateNo }}</van-col>
              <van-col span="4" id="uploadCard">
                <van-popover
                  v-model="uploadVisible"
                  get-container="#app"
                  placement="left"
                  trigger="click"
                >
                  <div class="upload-img" style="padding: 20px 20px 6px 20px;">
                    <van-uploader max-count="1" :after-read="cardHolder">
                      <template>
                        <van-icon :name="require('@/assets/images/card-positive.png')" size="100px" style="margin-right: 10px;" />
                      </template>
                    </van-uploader>
                    <van-uploader max-count="1">
                      <template>
                        <van-icon :name="require('@/assets/images/card-side.png')" size="100px" style="top: -0.2rem;" />
                      </template>
                    </van-uploader>
                  </div>
                  <template #reference>
                    <van-icon name="scan" size="20"/>
                  </template>
                </van-popover>
              </van-col>
          </van-row>
          <van-row>
              <van-col span="6"  class="h-text-color_gray-6">出生日期</van-col>
              <van-col span="18">{{ holderInfo.birthday }}</van-col>
          </van-row>
          <van-row>
              <van-col span="6" class="h-text-color_gray-6">性别</van-col>
              <van-col span="18">{{ holderInfo.gender === '1' ? '男' : '女' }}</van-col>
          </van-row> -->
      </div>
    </div>
    <div class="base h-group holder-edit" v-if="holderConfig">
        <div class="h-cell__content">
          <HYForm
            ref="holderFormRef"
            fmCode="holder"
            :config="holderConfig"
            :form="formData">
          </HYForm>
      </div>
    </div>
    <div class="holder-normal_bnt">
      <div class="holder-normal_bnt--fixed" >
        <van-button type="primary" block round @click="submit">确认</van-button>
      </div>
    </div>
    <van-popover v-model="uploadVisible" get-container="#uploadCard" placement="top">
    </van-popover>
</div>
</template>
<script>
import { mapState } from "vuex";
import { Notify } from "vant";
import util from '@/utils/common.js'
import { cloneDeep } from "lodash";
import HSelect from '@/components/form-items/HSelect.vue'
import dayjs from 'dayjs'
import HYForm from "@/components/Form.vue";
import config from './config/holder'
export default {
  name: "holderModel",
  components: { HSelect, HYForm },
  provide() {
    return {
      pageContext: this,
      pageForm:this.pageForm,
      pageLayout:this.pageLayout
    };
  },
  data() {
    return {
      formData: {
        name: '',
        certificateNo: ''
      },
      holderConfig: null,
      uploadVisible: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }],
      viewFormData: {

      },
      viewHolderConfig: {
        fmName: "投保人信息",
        fmCode: "holder",
        show: true,
        formItems: [
          {
            prop: "name",
            control: "HText",
            show: true,
            config: {
              label: "姓名",
              val: "",
              placeholder: "",
              disabled: false,
              required: true,
            }
          },
          {
            prop: "certificateNo",
            control: "HText",
            show: true,
            config: {
              label: "证件号码",
              val: "",
              placeholder: "",
              disabled: false,
              required: true,
            }
          },
          {
            prop: "birthday",
            control: "HText",
            show: true,
            config: {
              label: "出生日期",
              val: "",
              placeholder: "",
              disabled: false,
              required: true,
            }
          },
          {
            prop: "genderDictValue",
            control: "HText",
            show: true,
            config: {
              label: "性别",
              val: "",
              dictCode: "GENDER",
              placeholder: "",
              disabled: false,
              required: true,
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote", "holderInfo"]),
  },
  async created() {
    await this.initData();
    this.initLayoutBase();
    let holderData = cloneDeep(this.quote.holder)
    holderData.birthday = util.getBirthDay(holderData.certificateNo)
    if (holderData.gender === '1') {
      this.holderInfo.genderDictValue = '男'
    } else {
      this.holderInfo.genderDictValue = '女'
    }
    this.formData = holderData
    this.$store.dispatch("car/setHolderInfo", Object.assign(this.holderInfo, holderData))
  },
  mounted() {
  },
  methods: {
    dayjs,
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initLayoutBase() {
      this.holderConfig = config
    },
    async submit () {
      const validate = await this.$refs.holderFormRef.validateForm();
      if (!validate) {
        Notify({ type: "warning", message: "请完善信息" });
        return false
      }
      await this.$store.dispatch("car/setHolderInfo", this.formData);//保存缓存
      this.$router.go(-1)
    },
    cardHolder (obj) {
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.holder-normal{
  padding: 0 8px;
  background-color: $background-color;
  height: 100vh;
  &_bnt{
    height: 64px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
}
.holder-normal ::v-deep{
  .h-group{
    margin-bottom: 10px;
    border-radius: $border-radius-lg;
  }
  .base {
    position: relative;
    top: 10px;
    padding: 10px;
    background: #fff;
  }
  .h-cell__content{
      padding: 6px 8px;
      font-size: $font-size-sm;
      .van-row {
          padding:9px 0;
      }
  }
  .h-cell__title{
      font-weight: $font-weight-bold-lg;
      font-size: $font-size-md;
      span.icon {
          border-radius: $border-radius-sm;
          color: $white;
          padding: 3px;
          margin-right:6px;
      }
  }
  .h-cell{
      padding: 10px 8px;
  }
  .h-cell__value {
      color: #0066FF;
      font-size: 12px;
  }
  .van-field__label {
    color: #969799;
  }
  .holder-edit {
    .h-cell__content{
      padding: 6px 0px;
    }
  }
  .upload-img {
    padding: 20px;
  }
}
</style>
