import { request } from "@/api/service";
import areaJson from "@/assets/data/area";
import dayjs from "dayjs";
import store from '@/store'
const validator = {
  certificateNo: (obj, value) => {
    if (
      value &&
      obj.certificateType === "1" &&
      !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
        value
      )
    ) {
      return false;
    } else {
      return true;
    }
  },
  operatorCertificateNo: (obj, value) => {
    if (
      value &&
      obj.operator_certificateType === "1" &&
      !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
        value
      )
    ) {
      return false;
    } else {
      return true;
    }
  },
  pattern: (obj, value, patternStr) => {
    if (value && !patternStr.test(value)) {
      return false;
    } else {
      return true;
    }
  },
};
export default {
  fmName: "投保人",
  fmCode: "holder",
  show: true,
  disabled: false,
  formChange: (ctx) => {},
  formItems: [
    {
      prop: "ethnicGroup",
      control: "HSelect",
      show: true,
      config: {
        label: "民族",
        placeholder: "请选择民族",
        disabled: false,
        val: null,
        dictCode: "ETHNIC_GROUP",
        rules: [
          {
            required: true,
            disabled: false,
            message: "民族必填",
            trigger: "onChange",
          },
        ]
      },
    },
    {
      prop: "mobile",
      control: "HInput",
      show: true,
      config: {
        label: "手机号码",
        placeholder: "请输入手机号码",
        disabled: false,
        type: "number",
        maxlength: 11,
        val: null,
        rules: [
          {
            required: true,
            disabled: false,
            message: "手机号码必填",
            trigger: "onBlur",
          },
          {
            disabled: false,
            message: "手机号格式有误",
            trigger: "onBlur",
            validator: (rule, value, callback) => {
              const val = callback ? value : rule;
              const pattern = /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/;
              return validator.pattern(null, val, pattern);
            },
          },
        ],
      },
    },
    {
      prop: "address",
      control: "HInput",
      show: true,
      config: {
        label: "籍贯地址",
        placeholder: "请输入籍贯地址",
        disabled: false,
        val: null,
        rules: [
          {
            required: true,
            disabled: false,
            message: "籍贯地址必填",
            trigger: "onBlur",
          },
        ],
      },
    },
    {
      prop: "issuer",
      control: "HInput",
      show: true,
      config: {
        label: "证件签发机关",
        placeholder: "请输入证件签发机关",
        labelWidth: "7em",
        disabled: false,
        val: null,
        rules: [
          {
            required: true,
            disabled: false,
            message: "证件签发机关必填",
            trigger: "onBlur",
          },
        ]
      },
    },
    {
      prop: "certBeginDate",
      control: "HDatePicker",
      show: true,
      config: {
        label: "证件有效起期",
        placeholder: "请选择证件有效起期",
        labelWidth: "7em",
        disabled: false,
        val: null,
        maxDate: new Date(),
        rules: [
          {
            required: true,
            disabled: false,
            message: "证件有效起期必填",
            trigger: "onBlur",
          },
        ]
      },
    },
    {
      prop: "certEndDate",
      control: "HDatePicker",
      show: true,
      config: {
        label: "证件有效止期",
        placeholder: "请选择证件有效止期",
        labelWidth: "7em",
        disabled: false,
        val: null,
        rules: [
          {
            required: true,
            disabled: false,
            message: "证件有效止期必填",
            trigger: "onBlur",
          },
        ]
      },
    },
    {
      prop: "certLongFlag",
      control: "HRadio",
      show: true,
      config: {
        label: "是否长期标识",
        disabled: false,
        checkedColor: "#57ccc2",
        iconSize: "16px",
        val: "0",
        dictCode: "COMMON_IF",
        labelWidth: "10em",
        changeEvent: (ctx) => {
          const { pageContext, val } = ctx;
          if (val === '1') {
            pageContext.formData.certEndDate = new Date("2099-01-01")
          } else {
            pageContext.formData.certEndDate = null
          }
          store.dispatch("car/setHolderInfo", pageContext.formData)
        }
      }
    }
  ],
}
